import { AppErrorHandler, DEBUG_BAR_CONFIG, DebugBarConfig, ScrolledByService, sharePrefix } from '@aston/foundation';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { ErrorHandler, inject } from '@angular/core';
import { map, throttleTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AuthenticationService } from './authentication-module/services';
import { WebSocketService } from './shared-module/services';
import { AppStoreSelectors } from './root-store/app-store';
import { AppConfiguration } from './app.configuration';
import { AppConstants } from './app.constants';

export function onViewTransitionCreated({transition}) {
	const router = inject(Router);
	const currentUrl = router.lastSuccessfulNavigation.finalUrl;
	const targetUrl = router.getCurrentNavigation().finalUrl;
	if (!currentUrl || !targetUrl) return false;

	// Skip the transition if the only thing
	// changing is the fragment and queryParams
	const config: IsActiveMatchOptions = {
		paths: 'subset',
		matrixParams: 'ignored',
		fragment: 'ignored',
		queryParams: 'ignored',
	};
	if (router.isActive(targetUrl, config)
		// Also skip between two same /word/:id/deep/pages
		|| sharePrefix(router.serializeUrl(currentUrl), router.serializeUrl(targetUrl))
	) {
		transition.skipTransition();
	}
}

export const ctxHelpConnector = (store: Store) => ({
	cxtHelpBaseUrl: store.select(AppStoreSelectors.selectConfig).pipe(map(c => c?.knowledgeBaseUrl))
})

export function provideDebugBar() {
	return [{
		provide: DEBUG_BAR_CONFIG,
		useFactory: (
			errorHandler: ErrorHandler,
			configuration: AppConfiguration,
			scrollService: ScrolledByService,
			store: Store,
			auth: AuthenticationService,
			websocket: WebSocketService,
		): DebugBarConfig => ({
			configuration,
			scrollService,
			errors: errorHandler as AppErrorHandler,
			tenant$: store.select(AppStoreSelectors.selectTenant).pipe(map(t => ({ ...t, id: t.tenantId }))),
			sessionId$: store.select(AppStoreSelectors.selectSessionId),
			inactivityService: {
				inactivityCountdown$: store.select(AppStoreSelectors.selectInactivityCountDown).pipe(throttleTime(1000)),
				inGracefulPeriod$: store.select(AppStoreSelectors.selectInactivityTime).pipe(map(inactivity => inactivity >= AppConstants.MAX_INACTIVITY_TIME - AppConstants.INACTIVITY_WARNING_COUNTDOWN_BEFORE_LOGOUT)),
			},
			websocket,
			auth,
		}),
		deps: [
			ErrorHandler,
			AppConfiguration,
			ScrolledByService,
			Store,
			AuthenticationService,
			WebSocketService
		]
	}]
}