import { toObservable } from '@angular/core/rxjs-interop';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { computed, Signal, signal } from '@angular/core';

import { Loader } from './loader.class';

export class SignalLoader extends Loader {

	isLoading$: Observable<boolean> = combineLatest([
		toObservable(this.loading), this._count.pipe(map(count => count > 0))
	]).pipe(
		map(([loadingStore, loadingInternal]) => !!(loadingStore || loadingInternal)),
		// setTimeout because we're not in an Angular component
		tap(state => setTimeout(() => this.combinedIsLoading = state)),
		shareReplay(1));

	error$: Observable<string | null> = combineLatest(toObservable(this.error), this._errorMessage,
		(errorStore, errorInternal) => errorStore || errorInternal || null)
		// setTimeout because we're not in an Angular component
		.pipe(tap(state => setTimeout(() => this.combinedError = state)));

	neitherLoadingNorError: Observable<boolean> = combineLatest(this.isLoading$, this.error$, (r, l) => !(r || !!l));

	private combinedIsLoading = false;
	private combinedError: string | null = null;

	get isLoading() {
		return this.combinedIsLoading;
	}

	get errorMessage(): string | null {
		return this.combinedError;
	}

	static empty() {
		return new SignalLoader(signal(false), signal(null));
	}

	static fromCombinedSignal(inner: Signal<{ loading: boolean, error: string | null }>) {
		return new SignalLoader(
			computed(() => inner().loading),
			computed(() => inner().error),
		);
	}

	constructor(
		public loading: Signal<boolean>,
		public error: Signal<string | null>,
		autoStart = false,
	) {
		super(autoStart);
	}
}
