import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

export class HttpErrorMessage extends Error {
	key = 'Errors.';
	code: number;
	params = {};
	translated = '';
	functional: boolean;
	extraData: any;
	specificError: string;

	get status() {
		return (<HttpErrorResponse>this.originalError).status
	}

	constructor(public originalError?: Error) {
		super(originalError?.message);
		// https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, HttpErrorMessage.prototype);
		this.name = 'HttpErrorMessage';
	}

	translate(translateService: TranslateService) {
		this.translated = translateService.instant(this.key, this.params);
		return this;
	}

	appendOriginalExtraMessage(prefix = ' : ') {
		if (this.originalError && this.originalError['error']?.errorMessage)
			this.translated += prefix + this.originalError['error']?.errorMessage
		return this
	}

	fromCode(code: number) {
		this.code = code;
		return this;
	}

	resetKey(key: any) {
		this.key = 'Errors.' + key;
		return this;
	}

	appendKey(key: any) {
		this.key += '' + key;
		return this;
	}

	withParams(params: any) {
		this.params = { ...this.params, ...params };
		return this;
	}

	// backward compatibility
	toString() {
		return this.translated || this.key;
	}

	is(test: string | number) {
		return this.code === test || this.code === +test || this.key.includes(''+test) || this.originalError?.['error']?.specificError?.includes(test);
	}

	asFunctional(toggle = true) {
		this.functional = toggle;
		return this;
	}

	hasValidationErrorKey(errorKey: string): boolean  {
		return errorKey in ((this.originalError as any).error.validationErrors || {})
	}
}
